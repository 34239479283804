.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}
.menu_terciario {
  text-align: start;
  white-space: break-spaces !important;
  &.active{
    color: white !important;
    font-weight: bold;
    text-decoration: none;
    background: rgba(5, 5, 5, 0.5490196078);
    background: var(--cui-sidebar-nav-link-hover-bg, rgba(5, 5, 5, 0.5490196078));
  }
}