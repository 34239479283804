.noticias-portada-imagen {
  position: absolute;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  object-fit: contain;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
  @media screen and (max-width: $lg) {
    aspect-ratio: 1584/1055;
  }
  @media screen and (min-width: $lg) {
    aspect-ratio: 12/5;
  }
}

.noticias-portada-cargando {
  width: 100%;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: $lg) {
    aspect-ratio: 1584/1055;
  }
  @media screen and (min-width: $lg) {
    aspect-ratio: 12/5;
  }
}

.noticias-portada-filtro {
  width: 100%;
  background-color: #00000066;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0rem;
  @media screen and (min-width: $md) and (max-width: $lg) {
    height: 32%;
  }
  @media screen and (min-width: 623px) and (max-width: $md) {
    height: 25%;
  }
  @media screen and (max-width: 622px) {
    height: 40%;
  }
  @media screen and (min-width: $lg) and (max-width: 1070px) {
    height: 40%;
  }
  @media screen and (min-width: 1071px) and (max-width: $xl) {
    height: 25%;
  }
  @media screen and (min-width: $xl) and (max-width: 1260px) {
    height: 40%;
  }
  @media screen and (min-width: 1260px) {
    height: 25%;
  }
}

.noticias-portada-mensaje {
  @media screen and (min-width: $md) {
    right: 5%;
    left: 5%;
    top: 15%;
    height: 80%;
    max-height: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .texto1 {
      width: 100%;
      color: #fff;
      font-size: 1.25rem;
      text-shadow: 0.1em 0.1em 0.2em black;
    }
    .texto2 {
      width: 100%;
      color: #fff;
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 1.25rem;
      text-shadow: 0.1em 0.1em 0.2em black;
    }
    .btn {
      color: #fff;
      border-color: #fff;
      font-size: 1rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  @media screen and (max-width: $md) {
    right: 5%;
    left: 5%;
    top: 5%;
    height: 80%;
    max-height: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    div {
      width: 100%;
      .texto1 {
        width: 100%;
        color: #fff;
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-bottom: 0.25rem;
        text-shadow: 0.1em 0.1em 0.2em black;
      }
      .texto2 {
        width: 100%;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
        line-height: 1rem;
        text-shadow: 0.1em 0.1em 0.2em black;
      }
    }
    .btn {
      color: #fff;
      border-color: #fff;
      font-size: 0.75rem;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  display: block;
  position: absolute;
}

.noticias-portada-is-loading {
  .image {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @media screen and (max-width: $lg) {
      aspect-ratio: 1584/1055;
    }
    @media screen and (min-width: $lg) {
      aspect-ratio: 12/5;
    }
  }
}

.noticias-divisor {
  @media screen and (max-width: $lg) {
    display: block;
  }
  @media screen and (min-width: $lg) {
    display: none;
  }
}

.noticias-masinfo-titulo {
  font-size: 3rem;
  color: #77b05c !important;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.noticias-masinfo-noticias {
  .noticias-masinfo-bloque {
    border-left: var(--cui-header-divider-border-width, 1px) solid
      var(--cui-header-divider-border-color, #d8dbe0);
    .noticias-masinfo-card {
      border-top: var(--cui-header-divider-border-width, 1px) solid
        var(--cui-header-divider-border-color, #d8dbe0);
      padding: 10px;
    }
    .noticias-masinfo-card:first-child {
      border-top: none;
    }
  }
  .noticias-masinfo-bloque:first-child {
    border-left: none;
  }
}

.noticias-leermas {
  width: 100%;
  background-color: lightgray;
  padding: 10px;
  font-weight: 700;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.noticias-videos-titulo {
  font-size: 1.75rem;
  font-weight: 500;
  color: #82A55A !important;
}

.container-autoridades-noticias {
  @media screen and (min-width: $md){
    display: flex;
  }
  @media screen and (max-width: $md){
    display: block;
  }
  justify-content: space-between;

  & > div > p,
  & > div > a,
  & > b {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
  }
}

.page-item {
  span {
    padding-bottom: 9px;
  }
}
