@import 'variables';
@import 'custom';

.container-sitio{
  min-height: 2rem !important;
  @media screen and (max-width: $xl) {
    width: 100%;
  }
  @media screen and (min-width: $xl) {
    width: 85%;
  }
}

.nav-link-mc {
  border-top: 0px;
  border-bottom: 1px;
  border-color: #00000030;
  border-right: 0px;
  border-left: 0px;
  border-style: solid;
}

.nav-group-toggle-mc > .nav-link {
  border-top: 0px;
  border-bottom: 1px;
  border-color: #00000030;
  border-right: 0px;
  border-left: 0px;
  border-style: solid;
}

.nav-group-toggle-mc > .nav-group-toggle::after {
  background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.6%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"));
}

.logo-header-redes-sitios{
  @media screen and (max-width: $sm) {
    text-align: right;
    margin-top: 1rem;
  }
  @media screen and (min-width: $sm) {
    text-align: right;
    margin-top: 0.25rem;
  }
}

.titulo-sitios{
  font-size: 2.2rem !important;
  font-weight: bold !important;
  color: $negroER;
}

.encabezado-paginas-sitios {
  font-size: 1.75rem;
  font-weight: 500;
  color: #82A55A !important;
}

.encabezado-noticias-titulo {
  font-size: 1.75rem;
  font-weight: 500;
  font-weight: bold !important;
  text-transform: none;
  color: $negroER; 
}

.encabezado-noticias-titulo {
  font-size: 1.75rem;
  font-weight: 500;
  font-weight: bold !important;
  text-transform:none;

}

.encabezado-sitios{
  font-size: 0.85rem !important;
  color: $negroER;
}

.bread-sitios{
  li {
    color: $secondary;
    a{
      color: $secondary;
    }
  }
}

.dropdown-sitios{
  color: var(--cui-header-active-color, rgba(44, 56, 74, 0.95)) !important;
  font-weight: bold;
  padding: 0rem 1rem !important;
}

.header-divider-sitios {
  flex-basis: calc(100% + 0rem);
  height: 0;
  margin: 0rem 0rem;
  padding-bottom: 1rem;
  border-top: 3px solid #d8dbe0;
  border-top: var(--cui-header-divider-border-width, 3px) solid var(--cui-header-divider-border-color, #d8dbe0);
}

@mixin accesos($color) {
  /*border-radius: 1rem;*/
  border-color: $beigeER;
  @media screen and (max-width: $sm) {
    border-top: transparent;
    border-bottom: transparent;
    border-radius: 0.25rem;
  }    
  .card-header{
    color: $negroER;
    background-color: $color;
    opacity: 0.9;
    font-size: 1.5rem;
    font-weight: 600;
    border-bottom: none;
    @media screen and (min-width: $md) {
      height: 100%;
      min-height: 8.5rem;
      padding:0rem !important;
    }
    @media screen and (max-width: $sm) {
      height: 100%;
      min-height: 4.5rem;
      padding:0rem !important;
    }        
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;    
  }
  .card-footer{
    background-color: $verdeER04;
    border-top: none;
    @media screen and (max-width: $sm) {
      padding: 0.3rem 1rem;
    }    
  }
}

.accesos-1{
  @include accesos($beigeER)
}
.accesos-2{
  @include accesos($beigeER)
}
.accesos-3{
  @include accesos($beigeER)
}
.accesos-4{
  @include accesos($beigeER)
}
.accesos-5{
  @include accesos($beigeER)
}
.accesos-6{
  @include accesos($beigeER)
}
.accesos-7{
  @include accesos($beigeER)
}
.accesos-8{
  @include accesos($beigeER)
}

.destacados {
  border-radius: 5px;
}

.destacados-imagen {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  -o-object-fit: cover;
  object-fit: cover;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}

.filtro-destacados {
  @media screen and (min-width: $xs) and (max-width: $md) {
    height: 45%;
    background-color: #0000004F;
    position: absolute;
    bottom: 0rem;
    border-radius: 0px 0px 5px 5px;
    display: block;
    left: 12px;
    right: 12px;
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    height: 35%;
    background-color: #0000004F;
    position: absolute;
    bottom: 0rem;
    border-radius: 0px 0px 5px 5px;
    display: block;
    left: 12px;
    right: 12px;  
  }
  @media screen and (min-width: $lg) and (max-width: $xxl) {
    height: 26%;
    background-color: #0000004F;
    position: absolute;
    bottom: 0rem;
    border-radius: 0px 0px 5px 5px;
    display: block;
    left: 12px;
    right: 12px;  
  }
  @media screen and (min-width: $xxl) {
    height: 22%;
    background-color: #0000004F;
    position: absolute;
    bottom: 0rem;
    border-radius: 0px 0px 5px 5px;
    display: block;
    left: 12px;
    right: 12px;  
  }
}

.destacados-mensaje {
  @media screen and (min-width: $xs) and (max-width: $md) {
    right: 10vw;
    left: 10vw;
    top: 55%;
    height: 45%;
    max-height: 45%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      width: 100%;
      .texto1{
        width: 100%;
        color: #fff;
        font-size: 1.1rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.6rem;
      }
      .texto2{
        width: 100%;
        color: #fff;
        font-size: 0.9rem;
        line-height: 0.9rem;
        margin-bottom: 0.75rem;
      }
    }
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    right: 5vw;
    left: 5vw;
    top: 66%;
    height: 34%;
    max-height: 34%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      width: 100%;
      .texto1{
        width: 100%;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
        line-height: 1rem;
      }
      .texto2{
        width: 100%;
        color: #fff;
        font-size: 0.75rem;
        line-height: 0.75rem;
        margin-bottom: 0.25rem;
      }
    }
  }
  @media screen and (min-width: $lg) and (max-width: $xxl) {
    right: 5%;
    left: 5%;
    top: 74%;
    height: 26%;
    max-height: 26%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .texto1{
      width: 100%;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
    .texto2{
      width: 100%;
      color: #fff;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: $xxl) {
    right: 5%;
    left: 5%;
    top: 78%;
    height: 22%;
    max-height: 22%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .texto1{
      width: 100%;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
    .texto2{
      width: 100%;
      color: #fff;
      font-size: 1rem;
    }
  }
  display: block;
  position: absolute;
}

.embed-responsive{
  position:relative;
  display:block;
  width:100%;
  height: 100%;
  padding:0;
  overflow:hidden
}
.embed-responsive:before{
  display:block;
  content:""
}
.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  border:0
}
.embed-responsive-21by9:before{
  padding-top:42.85714%
}
.embed-responsive-16by9:before{
  padding-top:56.25%
}
.embed-responsive-4by3:before{
  padding-top:75%
}
.embed-responsive-1by1:before{
  padding-top:100%
}

.autoridades {
  background-color: $beigeER !important;
  color: $negroER !important;
  min-height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.titulos-autoridades {
  font-size: 1rem;
  color: $primary !important;
  font-weight: bold;
  margin-top: 0rem;
  text-align: left;
}

.organismos-autoridades {
  font-size: 1.5rem;
  color: $primary !important;
  font-weight: bold;
  margin-top: 3rem;
  text-align: left;
}

.titulos-contacto {
  font-size: 1rem;
  color: $primary !important;
  font-weight: bold;
  text-align: left;
  @media screen and (max-width: $md) {
    margin-top: 2rem;
  }
  @media screen and (min-width: $md) {
    margin-top: 0rem;
  }
}

.titulos-redes {
  font-size: 1rem;
  color: $primary !important;
  font-weight: bold;
  @media screen and (max-width: $md) {
    margin-top: 2rem;
    text-align: left;
  }
  @media screen and (min-width: $md) {
    margin-top: 0rem;
    text-align: right;
  }
}

.logos-redes {
  margin-top: 0rem;
  @media screen and (max-width: $md) {
    text-align: left;
  }
  @media screen and (min-width: $md) {
    text-align: right;
  }
}

.usointerno-autoridades {
  font-size: 1.3rem;
  color: $primary !important;
  font-weight: 200;
  margin-top: 0px;
  @media screen and (max-width: $md) {
    text-align: left;
  }
  @media screen and (min-width: $md) {
    text-align: right;
  }
}

.pie-organismo {
  background-color: $beigeER !important;
  color: $negroER !important;
  min-height: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.er404 {
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/ripped.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.image {
  img {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.is-loading {
  .image {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    height: 260px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.is-loading-busqueda {
  .image {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    height: 190px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.mapa {
  width: 100%;
  height: 500px;
}

.mapa-div {
  @media screen and (max-width: $md) {
    padding-bottom: 500px;
  }
}
.mapa-dibujable {
  @media screen and (min-width: $md) {
    width: 100%;
    height: 850px;
  }
  @media screen and (max-width: $md) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 98vw;
    height: 500px;
    box-sizing: border-box;
  }
}